var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("page-toolbar", {
        attrs: { title: "Template" },
        scopedSlots: _vm._u([
          {
            key: "contents",
            fn: function () {
              return [
                _c("v-spacer"),
                _c(
                  "div",
                  { staticStyle: { width: "400px" } },
                  [
                    _c("v-select", {
                      attrs: {
                        outlined: "",
                        dense: "",
                        "hide-details": !_vm.templateCreated,
                        messages:
                          "Page refresh may be required for rendering the new templates",
                        items: _vm.templateNamesWithAux,
                        label: "Template name",
                      },
                      on: { change: _vm.onTemplateSelect },
                      scopedSlots: _vm._u(
                        [
                          _vm.templateCreated
                            ? {
                                key: "message",
                                fn: function ({ message }) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "darkorange",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [_vm._v(_vm._s(message))]
                                    ),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.templateName,
                        callback: function ($$v) {
                          _vm.templateName = $$v
                        },
                        expression: "templateName",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      text: "",
                      color: "indigo",
                      disabled: !_vm.projectName,
                      to: "/console/template/create",
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-plus-box-multiple-outline"),
                    ]),
                    _vm._v(" Create template "),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      text: "",
                      color: "indigo",
                      disabled: !_vm.templateName,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openTemplateEditorWindow.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-file-code-outline"),
                    ]),
                    _vm._v(" Open File Editor "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("dialog-create-template", {
        ref: "dialogCreateTemplate",
        attrs: {
          client: _vm.client,
          "project-name": _vm.projectName,
          presets: _vm.presets,
        },
        on: { complete: _vm.onCreationComplete },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _vm._v(" I am a sample "),
      _c("b", [_vm._v("instruction")]),
      _vm._v(" page content."),
      _c("br"),
      _c("span", { staticClass: "caution" }, [
        _vm._v("Modify me before you publish this task!"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <Scatt
        ref="scatt"
        v-bind:on-keydown-callback="onKeydown"
    >
        <template v-slot:app-bar="{ callback, state }">
            <AppBarButton
                v-bind:disabled="state.isUndoTargetEmpty"
                v-on:click.stop="callback.undoChange"
            >
                <v-icon>mdi-undo</v-icon>Undo
            </AppBarButton>

            <AppBarButton
                v-bind:disabled="state.isRedoTargetEmpty"
                v-on:click.stop="callback.redoChange"
            >
                <v-icon>mdi-redo</v-icon>Redo
            </AppBarButton>

            <AppBarButton
                v-bind:disabled="state.isCurrentScattDataSameAsLastSaved"
                v-on:click.stop="showSaveDialog(callback)"
            >
                <v-icon>mdi-content-save</v-icon>Save
            </AppBarButton>

            <AppBarButton
                v-on:click.stop="$_submit(callback)"
            >
                <v-icon>mdi-send</v-icon>Submit
            </AppBarButton>

            <AppBarButton v-on:click.stop="callback.showViewFilter">
                <v-icon>mdi-format-list-checkbox</v-icon>Filter view
            </AppBarButton>

            <AppBarButton v-on:click.stop="callback.showHelp">
                <v-icon>mdi-help-circle</v-icon>Help
            </AppBarButton>

        </template>
    </Scatt>
</template>
<script>
import AppBarButton from '@/components/template-ui/Scatt/external/components/AppBar/AppBarButton.vue'
import Scatt from '@/components/template-ui/Scatt.vue'
import nanoMixIn from '@/mixins/nano';

//const scattDataFileName = 'scattData.json';

export default {
    mixins: [nanoMixIn],
    components: { Scatt, AppBarButton },

    data() {
        return {
            defaultNanoProps: {
                videoFilePath: '/static/annchan/videos4/video27.mp4'
            },
            defaultNanoAnswers: {
                saved: null,
                error: null,
                scattDataRawObj: null
            }
        };
    },

    methods: {
        load() {
            this.$refs.scatt.loadScattFile(this.nano.props.scattDataFilePath);
            this.$refs.scatt.loadMediaFile(this.nano.props.videoFilePath);
        },

        async onKeydown(event, { callback, state }) {
            let withCtrl = event.ctrlKey;
            switch (event.code) {
            case 'KeyS':
                if (withCtrl && !state.isCurrentScattDataSameAsLastSaved) {
                    await this.showSaveDialog(callback);
                    return true;
                }
                break;
            }
            return false;
        },

        async showSaveDialog(callback) {
            let isSaved = await new Promise(resolve => {
                let isSaved = false;
                callback.initializeDialog(
                    'general-confirmation',
                    {
                        title: 'Save',
                        message: 'Are you sure to save?',
                        okCallback: () => {
                            this.nano.ans.scattDataRawObj = callback.saveScattData();
                            isSaved = true;
                            callback.registerFooterMessage('Scatt data saved.');
                        },
                        finalizeCallback: () => {
                            callback.finalizeDialog();
                            resolve(isSaved);
                        }
                    }
                )
            });

            if (isSaved) {
                await new Promise(resolve => callback.initializeDialog(
                    'general-message',
                    {
                        title: 'Save successful',
                        finalizeCallback: () => {
                            callback.finalizeDialog();
                            resolve();
                        }
                    }
                ));
            }
        },
        
        async $_submit(callback) {
            await new Promise(resolve => callback.initializeDialog(
                'general-confirmation',
                {
                    title: 'Submit',
                    message: 'Are you sure to submit?',
                    okCallback: () => {
                        let scattDataRawObj = callback.getCurrentScattData();
                        this.nano.ans = {
                            saved: true,
                            error: null,
                            scattDataRawObj: scattDataRawObj,
                        }
                        this.submit();
                        this.isFinalized = true;
                    },
                    finalizeCallback: () => {
                        callback.finalizeDialog();
                        resolve();
                    }
                }
            ));
        },
    },
    mounted() {
        this.load();
    }
};
</script>
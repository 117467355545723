var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("template-select-toolbar", {
        attrs: {
          client: _vm.client,
          projectName: _vm.projectName,
          templateNames: _vm.templateNames,
          presets: _vm.presets,
        },
        on: { "template-change": _vm.loadTemplate },
      }),
      _c(
        "v-row",
        { staticStyle: { height: "calc(100% - 53px)" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-fade-transition",
                { attrs: { "hide-on-leave": "" } },
                [
                  _c(_vm.tmplComponent, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tmplComponent,
                        expression: "tmplComponent",
                      },
                    ],
                    tag: "component",
                    on: {
                      submit: _vm.showSubmitResults,
                      "set-nano-props": _vm.updateNanoProps,
                      "response-update": _vm.updateNanoAnswers,
                    },
                  }),
                ],
                1
              ),
              _c("v-fade-transition", { attrs: { "hide-on-leave": "" } }, [
                _c("iframe", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tmplHTML,
                      expression: "tmplHTML",
                    },
                  ],
                  ref: "iframe",
                  staticStyle: { width: "100%", height: "100%", border: "0" },
                  attrs: { srcdoc: _vm.tmplHTML },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.tmplComponent
        ? _c("response-navigation-drawer", {
            attrs: {
              "nano-props": _vm.nanoProps,
              "nano-answers": _vm.nanoAnswers,
            },
          })
        : _vm._e(),
      _c("dialog-submit-response", {
        ref: "dialogSubmitResponse",
        attrs: { nanoAnswers: _vm.sentNanoAnswers },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }